import soledis from './global';

const sldSearchWidgetRemoveClick = () => {
  const searchBar =document.querySelector('#header .search-widget-form');
  const button = document.querySelector('#header .search-widget-button');
  if (searchBar !== null && button !== null) {
    button.addEventListener('click', (e) => {
      let field = document.querySelector('#header .search-widget-field');
      //if (!searchBar.classList.contains('active')) {
        e.preventDefault();
        searchBar.classList.add('active');
        field.focus();
      //   let overlay = document.createElement('div');
      //   overlay.classList.add('sld-overlay');
      //   overlay.addEventListener('click', () => {
      //     searchBar.classList.remove('active');
      //     overlay.remove();
      //   })
      //   document.body.append(overlay);
      // }
    });
  }
}

const sldDisplayStockStore = () => {
  let btnDisplay = document.querySelector('button#stockbystore');
  if (btnDisplay !== null) {
    document.body.addEventListener('click', (e) => {
      if (e.target.getAttribute('id') === 'stockbystore') {
        let btn = e.target;
        if (btn.classList.contains('active')) {
          btn.classList.remove('active');
        } else {
          btn.classList.add('active');
        }
      }
      else {
        let btnActive = document.querySelector('#stockbystore.active');
        if (btnActive !== null) {
          btnActive.classList.remove('active');
        }
      }
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  // remove comment if you want the animation to display search bar
  sldSearchWidgetRemoveClick();
  sldDisplayStockStore();
});