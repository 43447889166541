export default class ScrollSmooth {
  constructor(options) {
    this.version = '1.1.0';
    this.anchors = [];
    this.options = ScrollSmooth.defaultOptions();
    this.setOptions(options);
    this.init();
  }

  static defaultOptions() {
    return {
      during: 300,
      selector: '.sld-js-anchor',
      animate: 'linear',
      block: null,
    };
  }

  setOptions(options) {
    this.options = ScrollSmooth.defaultOptions();
    if (typeof options !== 'undefined') {
      if (typeof options === 'object') {
        this.options.during = (typeof options.during === 'number') ? options.during : this.options.during;
        this.options.selector = (typeof options.selector === 'string') ? options.selector : this.options.selector;
        this.options.animate = (typeof options.animate === 'string') ? options.animate : this.options.animate;
        this.options.block = (typeof options.block === 'string') ? options.block : this.options.block;
      } else if (typeof options === 'number') {
        this.animationScroll(options);
      } else {
        console.log('Error : the toast parameters is not string or object.');
      }
    }
  }

  init() {
    if (document.querySelector(this.options.selector) !== null) {
      document.querySelectorAll(this.options.selector).forEach((button) => {
        const anchorLink = button.getAttribute('href');
        const block = document.querySelector(anchorLink);
        if (block !== null) {
          this.supplyAnchor(button, anchorLink, block);
          this.eventClick(button);
        }
      });
    }
    if (this.options.block !== null) {
      const block = document.querySelector(this.options.block);
      if (block !== null) {
        const position = ScrollSmooth.position(block);
        this.animationScroll(position.top);
      }
    }
    this.eventResize();
  }

  addAnchor(button, anchorLink, block) {
    this.supplyAnchor(button, anchorLink, block);
    this.eventClick(button);
  }

  supplyAnchor(button, anchorLink, block) {
    const position = ScrollSmooth.position(block);
    button.setAttribute('data-position-top', position.top);
    this.anchors.push({
      button,
      anchorLink,
      block,
      position,
    });
  }

  static position(block) {
    let blockPosition = block.getBoundingClientRect();
    let blockTop = blockPosition.top + window.scrollY;
    return {
      top: blockTop,
      height: block.offsetHeight,
    };
  }

  eventClick(button) {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      const positionTop = Number(button.getAttribute('data-position-top'));
      this.animationScroll(positionTop);
    });
  }

  changePosition() {
    const anchors = sld.scrollSmooth.anchors;
    if (anchors.length > 0) {
     anchors.forEach((anchor, key) => {
       const position = ScrollSmooth.position(anchor.block);
       anchors[key].position = position;
       anchors[key].button.setAttribute('data-position-top', position.top);
     });
    }
  }

  eventResize() {
    window.addEventListener('resize', this.changePosition);
  }

  animationScroll(destination, duration, easing, callback) {
    const during = typeof duration === 'undefined' ? this.options.during : duration;
    const animate = typeof easing === 'undefined' ? this.options.animate : easing;
    // TODO add easing animation
    const easings = {
      linear(t) {
        return t;
      },
    };

    const start = window.scrollY;
    const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

    const documentHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight,
    );
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
    const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
    const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight
      ? documentHeight - windowHeight
      : destinationOffset);

    if (!('requestAnimationFrame' in window)) {
      window.scroll(0, destinationOffsetToScroll);
      if (callback) {
        callback();
      }
      return;
    }

    function scroll() {
      const now = 'now' in window.performance ? performance.now() : new Date().getTime();
      const time = Math.min(1, ((now - startTime) / during));
      const timeFunction = easings[animate](time);
      const pageHeight = document.body.offsetHeight;
      const position = Math.ceil(window.pageYOffset);
      window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));

      if ((position > (destinationOffsetToScroll - 2) && position < (destinationOffsetToScroll + 2)) || ((position + window.innerHeight) >= pageHeight && destinationOffsetToScroll !== 0)) {
        if (callback) {
          callback();
        }
        return;
      }
      requestAnimationFrame(scroll);
    }
    scroll();
  }
}
