import $ from 'jquery';
import prestashop from 'prestashop';

$(document).ready(function () {
  createProductSpin();
  createInputFile();
  zoomImage();
  lightboxImage();
  coverImage();
  imageScrollBox();
  moveProductAttributes();
  addAccordionActiveClass();
  moveShippingDelivery();

  $('body').on('change', '.product-variants *[name]', function () {
    togglePendingRefreshIcon('in');
  });

  prestashop.on('updatedProduct', function (event) {
    createInputFile();
    zoomImage();
    coverImage();
    imageScrollBox();
    moveProductAttributes();
    moveShippingDelivery();

    if (event && event.product_minimal_quantity) {
      //const minimalProductQuantity = parseInt(event.product_minimal_quantity, 10);
      //const quantityInputSelector = prestashop.selectors.quantityWanted;
      //const quantityInput = $(quantityInputSelector);

      $(prestashop.selectors.quantityWanted).trigger('touchspin.updatesettings', {min: parseInt(event.product_minimal_quantity, 10)});
    }
    
    $('#js_mfp_gallery').replaceWith(event.product_images_modal);
    lightboxImage();
    togglePendingRefreshIcon('out');

    if ($('[data-button-action="add-to-cart"]').is(':disabled')) {
      $('.product-add-to-cart').addClass('add-to-cart-disabled');
    } else {
      $('.product-add-to-cart').removeClass('add-to-cart-disabled');
    }
  });

  // varCustomActionAddToCart
  handleAddToCart();  
  
});

var productResizeTimer;
$(window).on('resize', function() {
  clearTimeout(productResizeTimer);
  productResizeTimer = setTimeout(function() {
    zoomImage();
  }, 250);
});

// overwrite 'add-to-cart'
function handleAddToCart() {
  $('body').off('click', '[data-button-action="add-to-cart"]');
  $('body').on('click', '[data-button-action="add-to-cart"]', function (event) {
    event.preventDefault();
    let $btn = $(this);

    if ($('#quantity_wanted').val() > $('[data-stock]').data('stock') && $('[data-allow-oosp]').data('allow-oosp').length === 0) {
      $btn.attr('disabled', 'disabled');
    } else {
      let $form = $(event.target).closest('form');
      let query = $form.serialize() + '&add=1&action=update';
      let actionURL = $form.attr('action');

      let isQuantityInputValid = function($input) {
        let validInput = true;

        $input.each(function (index, input) {
          let $input = $(input);
          let minimalValue = parseInt($input.attr('min'), 10);
          if (minimalValue && $input.val() < minimalValue) {
            onInvalidQuantity($input);
            validInput = false;
          }
        });

        return validInput;
      };

      let onInvalidQuantity = function($input) {
        $input.parents('.product-add-to-cart').first().find('.product-minimal-quantity').addClass('error');
        $input.parent().find('label').addClass('error');
      };

      let $quantityInput = $form.find('input[min]');
      if (!isQuantityInputValid($quantityInput)) {
        onInvalidQuantity($quantityInput);

        return;
      }

      $btn.removeClass('added').addClass('disabled');

      $.post(actionURL, query, null, 'json').then(function(resp) {
        prestashop.emit('updateCart', {
          reason: {
            idProduct: resp.id_product,
            idProductAttribute: resp.id_product_attribute,
            idCustomization: resp.id_customization,
            linkAction: 'add-to-cart',
            cart: resp.cart
          },
          resp
        });

        if (resp.success) {
          if (typeof(varPSAjaxCart) !== 'undefined' && varPSAjaxCart) {
            $btn.addClass('added');
          } else {
            window.location.href = prestashop.urls.pages.cart + '?action=show';
          }
        }

      }).fail(function (resp) {
        prestashop.emit('handleError', { eventType: 'addProductToCart', resp });
      });
    }
  });
}

function togglePendingRefreshIcon(fade_status) {
  // varProductPendingRefreshIcon
  if (fade_status == 'in') {
    $('.js-product-refresh-pending-query').fadeIn();
  } else {
    $('.js-product-refresh-pending-query').fadeOut();
  }
}

function zoomImage() {
  $('#product .zoomWrapper .js-main-zoom').css('position','static').unwrap();
  $('#product .zoomContainer').remove();

  $('#product .js-enable-zoom-image .js-main-zoom').elevateZoom({
    zoomType: 'inner',
    gallery: 'js-zoom-gallery',
    galleryActiveClass: 'selected',
    imageCrossfade: true,
    cursor: 'crosshair',
    easing: true,
    easingDuration: 300,
    zoomWindowFadeIn: 500,
    zoomWindowFadeOut: 300,
  });
}

function lightboxImage() {
  const $gallery = $('#js_mfp_gallery');
  if ($gallery.length) {
    let zClose = $gallery.data('text-close'),
        zPrev = $gallery.data('text-prev'),
        zNext = $gallery.data('text-next');

    $('.js_mfp_gallery_item').magnificPopup({
      type: 'image',
      tClose: zClose,
      tLoading: '<div class="uil-spin-css"><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div></div>',
      removalDelay: 500,
      mainClass: 'mfp-fade',
      closeOnBgClick: true,
      gallery: {
        enabled: true,
        tPrev: zPrev,
        tNext: zNext,
        tCounter: '%curr% / %total%',
      },
      image: {
        verticalFit: false,
        titleSrc: function(item) {
          if (item.el.attr('data-title')) {
            return '<span>' + item.el.attr('data-title') + '</span>';
          } else {
            return '';
          }
        }
      }
    });

    $('.js-mfp-button').on('click', function() {
      //let imageId = $('#js-zoom-gallery .js-thumb.selected').data('id-image');
      $('.js_mfp_gallery_item').filter('[data-id-image="' + $('#js-zoom-gallery .js-thumb.selected').data('id-image') + '"]').trigger('click');
    });
  }
}

function coverImage() {
  $('#product .js-cover-image .js-thumb').on(
    'click',
    function (event) {
      $('.js-thumb.selected').removeClass('selected');
      $(event.currentTarget).addClass('selected');
      $('.js-qv-product-cover').prop('src', $(event.currentTarget).data('image'));
    }
  );
}

function imageScrollBox() {
  $('.js-product-thumbs-scrollbox').makeFlexScrollBox();
  $('.js-mobile-images-scrollbox').makeFlexScrollBox();
}

function createInputFile() {
  $(prestashop.themeSelectors.fileInput).on('change', function(event) {
    let target, file;

    if ((target = $(event.currentTarget)[0]) && (file = target.files[0])) {
      $(target).prev().text(file.name);
    }
  });
}

function createProductSpin() {
  const $quantityInput = $(prestashop.selectors.quantityWanted);
  $quantityInput.makeTouchSpin();

  $(prestashop.themeSelectors.touchspin).off('touchstart.touchspin');

  $quantityInput.focusout(function () {
    if ($quantityInput.val() === '' || $quantityInput.val() < $quantityInput.attr('min')) {
      $quantityInput.val($quantityInput.attr('min'));
      $quantityInput.trigger('change');
    }
  });

  $('body').on('change keyup', prestashop.selectors.quantityWanted, function (e) {
    if ($quantityInput.val() !== '') {
      $(e.currentTarget).trigger('touchspin.stopspin');
      prestashop.emit('updateProduct', {
        eventType: 'updatedProductQuantity',
        event: e,
      });
    }
  });
}

function moveProductAttributes() {
  // const attributes = document.querySelector('.js-product-attributes-source');
  // const attributesDest = document.querySelector('.js-product-attributes-destination');
  const attributeReference = document.querySelector('.js-product-attributes-source .product-reference');
  const attributeReferenceDest = document.querySelector('.js-product-attribute-reference');
  // const attributeQuantities = document.querySelector('.js-product-attributes-source .product-quantities');
  // const attributeQuantitiesDest = document.querySelector('.js-product-attribute-quantities');
  const attributeManufacturer = document.querySelector('.js-product-attributes-source .product-manufacturer');
  const attributeManufacturerDest = document.querySelector('.js-product-attribute-manufacturer');
  // const attributeEan13 = document.querySelector('.js-product-attributes-source .product-specific-references');
  // const attributeEan13Dest = document.querySelector('.js-product-attribute-Ean13');
  const attributeAvailability = document.querySelector('.js-product-availability-source .js-product-availability');
  const attributeAvailabilityDest = document.querySelector('.js-product-availability-destination');
  const attributeCatalog = document.querySelector('.js-product-attribute-catalog-reference .product-reference-catalog');
  const attributeCatalogDest = document.querySelector('.js-product-attribute-catalog-reference-dest');

  // if (attributes !== null && attributesDest !== null) {
  //   attributesDest.innerHTML = '';
  //   attributesDest.append(attributes);
  // }

  // manufacturer
  if (attributeManufacturer !== null && attributeManufacturerDest !== null) {
    attributeManufacturerDest.innerHTML = '';
    attributeManufacturerDest.append(attributeManufacturer);
  }

  // reference
  if (attributeReference !== null && attributeReferenceDest !== null) {
    attributeReferenceDest.innerHTML = '';
    attributeReferenceDest.append(attributeReference);
  }

  // reference catalog
  if (attributeCatalog !== null && attributeCatalogDest !== null) {
    attributeCatalogDest.innerHTML = '';
    attributeCatalogDest.append(attributeCatalog);
  }

  // quantity in stock
  // if (attributeQuantities !== null && attributeQuantitiesDest !== null) {
  //   attributeQuantitiesDest.innerHTML = '';
  //   attributeQuantitiesDest.append(attributeQuantities);
  // }

  // availability
  if (attributeAvailability !== null && attributeAvailabilityDest !== null) {
    attributeAvailabilityDest.innerHTML = '';
    let availabilityObject = attributeAvailability.cloneNode(true);
    attributeAvailabilityDest.append(availabilityObject);
  }
}

function addAccordionActiveClass() {
  $('.js-product-accordions [data-toggle="collapse"]').on('click', function() {
    $(this).closest('.panel').toggleClass('active');
  });
}

function moveShippingDelivery() {
  let $deliverySrc = document.querySelector('.product-prices .sld-js-product-shipping-delivery-src');
  let $deliveryDest = document.querySelector('.sld-js-product-shipping-delivery-dest');

  if ($deliveryDest !== null && $deliverySrc !== null) {
    $deliveryDest.innerHTML = '';
    $deliverySrc.classList.remove('d-none');
    $deliveryDest.appendChild($deliverySrc);
  }
}